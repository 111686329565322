import React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import SEO from "../../components/seo";

import Layout from "../../components/layout";

const Note = ({
  data: {
    note: {
      body,
      frontmatter: { title, keywords },
    },
  },
  ...props
}) => {
  return (
    <Layout {...props}>
      <SEO title={title} keywords={keywords} />
      <MDXRenderer>{body}</MDXRenderer>
    </Layout>
  );
};

export default Note;
